// src/theme.ts
import { createTheme } from '@mui/material/styles';

// Erstellen eines benutzerdefinierten Themes mit MUI
const theme = createTheme({
  palette: {
    primary: {
      main: '#58585B', // Definiert die Hauptfarbe für die primäre Farbpalette
    },
    secondary: {
      main: '#99A91D', // Definiert die Hauptfarbe für die sekundäre Farbpalette
    },
    text: {
      primary: '#FFF', // Definiert die primäre Schriftfarbe
      secondary: '#58585B', // Definiert die sekundäre Schriftfarbe
    },
  },
  typography: {
    fontFamily: '"Montserrat", sans-serif', // Globale Schriftart
    fontSize: 16, // Standard-Schriftgröße für Text
    h1: {
      fontSize: '2.5rem', // Schriftgröße für h1-Überschriften
    },
    h2: {
      fontSize: '2rem', // Schriftgröße für h2-Überschriften
    },
    body1: {
      fontSize: '1rem', // Schriftgröße für normalen Text (body1)
    },
    // Hier können weitere Schriftarten und -größen definiert werden
  },
});

export default theme;
